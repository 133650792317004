const formatter = {}

formatter.stateFormatter = (s) => {
    switch (s.state) {
        case 2:
            return "已完成"
        case 1:
            return "进行中"
        case 0:
            return "待沟通"
        default:
            return s.state
    }
}

formatter.dateTimeFormatter = (row, column) => {
    console.log(row, column)
    let value = row[column.property]
    value = value.match(/(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/g);
    // value = value.replace('T', '');
    return value
}

export default formatter
