<template>
    <el-form :model="form" :inline="true">
        <el-form-item label="状态">
            <el-select v-model="form.state" placeholder="选择类型" style="width: 100px" size="small" clearable>
                <el-option label="待沟通" value="0"></el-option>
                <el-option label="进行中" value="1"></el-option>
                <el-option label="已完成" value="2"></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="院区">
            <select-hospital v-model="form.hospital_id" style="width:150px"/>
        </el-form-item>
        <el-form-item label="科室">
            <select-department v-model="form.department_id" :hospital_id="form.hospital_id" :type_id="form.type_id" style="width:100px"/>
        </el-form-item>
        <el-form-item label="医生">
            <select-doctor v-model="form.doctor_id" :hospital_id="form.hospital_id" :type_id="form.type_id" :department_id="form.department_id" style="width:100px"/>
        </el-form-item>
        <el-form-item label="申请日期">
            <el-date-picker style="width: 140px"
                            v-model="form.date"
                            type="date"
                            align="right"
                            unlink-panels
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            size="small"
            >
            </el-date-picker>
        </el-form-item>
        <el-form-item label="关键词">
            <el-input v-model="form.keywords" style="width: 200px" size="small"></el-input>
        </el-form-item>
        <el-form-item>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <!--                        <el-button type="default" @click="doExport">导出 EXCEL</el-button>-->
        </el-form-item>
    </el-form>
</template>

<script>
    export default {
        data() {
            return {
                hospitalList: [],
                departmentList: [],
                form: {
                    source: '',
                    type: '',
                    hospital_id: '',
                    department_id: '',
                    date: '',
                    keywords: '',
                }
            }
        },
        methods: {
            handleSearch() {
                this.$emit('search', this.form);
            },
            async getData() {
                const hp = await this.$http.get(`/mingde/hospital`)
                this.hospitalList = hp.data.data
                if (this.form.hospital_id){
                    const dt = await this.$http.get(`/mingde/department/?type=${this.form.type}&hospital_id=${this.form.hospital_id}`)
                    this.departmentList = dt.data.data
                }
            }
        },
        mounted() {
            this.getData()
        }
    }
</script>
