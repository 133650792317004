<template>
    <div class="table-page">
<!--        <div class="page-header">-->
<!--            <el-page-header @back="$router.go(-1)" content="咨询记录"/>-->
<!--        </div>-->
        <el-breadcrumb separator="/" style="margin-bottom: 20px">
            <el-breadcrumb-item :to="{ path: '/main/dashboard' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item>线上咨询</el-breadcrumb-item>
            <el-breadcrumb-item>咨询记录</el-breadcrumb-item>
        </el-breadcrumb>
        <div style="text-align: center" class="hidden-sm-and-down">
            <search @search="handleSearch"/>
        </div>
        <div class="page-main">
            <el-table :data="table.data" style="width: 100%"
                      height="100%"
                      :row-class-name="tableRowClassName"
                      v-loading="loading" border>
                <el-table-column type="expand">
                    <template slot-scope="props">
                        <div style="margin-left:50px;line-height: 30px;">
                            <div>患者姓名：{{ props.row.patient}}</div>
                            <div>身份证号：{{ props.row.idnum}}</div>
                            <div>联系电话：{{ props.row.phone}}</div>
                            <div>病情简述：{{ props.row.desc}}</div>
                            <div>备&nbsp; &nbsp; &nbsp; &nbsp;注：{{ props.row.remark}}</div>
                            <div>预约时间：{{ props.row.createAt}}</div>
                            <div>操作账号：{{ props.row.owner}}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center"
                                 v-for="(v, idx) in table.columns"
                                 :prop="v.field"
                                 :label="v.title"
                                 :width="v.width"
                                 :formatter="v.formatter"
                                 :key="idx">
                </el-table-column>
                <el-table-column align="center" label="操作" width="180">
                    <template slot-scope="scope">
                        <el-button @click="handleUpdate(scope.row)" size="mini" type="default">沟通记录</el-button>
                        <el-button type="danger" size="mini" @click="handleDel(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="page-footer">
            <el-pagination layout="total, prev, pager, next, sizes" :page-sizes="[10, 20, 30, 50]"
                           :total="table.total" :page-size="table.pageSize"
                           @current-change="handleCurrentChange"
                           @size-change="handleSizeChange"/>
        </div>
    </div>
</template>

<script>
    import Search from "./widgets/search"
    import formatter from "./widgets/formatter"

    export default {
        components: {
            Search
        },
        data() {
            return {
                loading: false,
                search: {},
                table: {
                    columns: [
                        {title: '院区', field: 'hospital.name', width: ''},
                        {title: '科室', field: 'department.name', width: ''},
                        {title: '申请日期', field: 'createAt', width: '', formatter: formatter.dateTimeFormatter},
                        {title: '患者姓名', field: 'patient', width: ''},
                        {title: '医生姓名', field: 'doctor.name', width: ''},
                        {title: '预约状态', field: 'state', width: '', formatter: formatter.stateFormatter},
                        {title: '患者来源', field: 'source', width: ''},
                    ],
                    data: [],
                    total: 0,
                    page: 1,
                    pageSize: 10
                },
            }
        },
        methods: {
            tableRowClassName({row}) {
                if (row.state === -1) {
                    return 'warning-row';
                } else if (row.state > 0) {
                    return 'success-row';
                }
                return '';
            },
            handleCurrentChange(e) {
                this.table.page = e
                this.getData()
            },
            handleSizeChange(e) {
                this.table.pageSize = e
                this.getData()
            },
            handleUpdate(row) {
                this.form = row
                this.$router.push(`/main/consult/form?id=${row.id}`)
            },
            async setState(row, state) {
                row.state = state
                const resp = await this.$http.put(`/mingde/consult/${row.id}`, row)
                if (resp.data.code === 200) {
                    this.$message.success("设置成功")
                    this.getData()
                } else {
                    this.$message.error("设置失败")
                }
            },
            async handleDel(row) {
                const confirm = await this.$confirm(`确认删除 ${row.patient} 的记录吗？`)
                if (!confirm) return
                const resp = await this.$http.delete(`/mingde/consult/${row.id}`)
                if (resp.data.code === 200) {
                    this.$message.success("删除成功")
                    this.getData()
                } else {
                    this.$message.error("删除失败")
                }
            },
            async getData() {
                let params = this.search
                params['page'] = this.table.page
                params['size'] = this.table.pageSize
                const resp = await this.$http.get('/mingde/consult/', {params: params})
                this.table.data = resp.data.data.data || []
                this.table.total = resp.data.data.total
            },
            handleSearch(e) {
                this.table.page = 1
                this.search = e
                this.getData()
            },
        },
        mounted() {
            this.getData()
        }
    }
</script>

<style>
    .warning-row {
        background-color: #ffc0be !important;
    }

    .success-row {
        background-color: #f0f9eb !important;
    }
</style>
